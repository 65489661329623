<template>
  <div class="content-onglets table">
        <table class="comparateur2 text-left border-top-gray">  
            <tr v-if="data['ma_date']">
                <td class="text-left bold"><strong> {{$t('Date_AMM')}} </strong></td>
                <td class="text-left"> {{DDMMMYY(data['ma_date'])}} </td>
            </tr>
            <tr v-if="data['submission_date']">
                <td  width="15%"  class="bold bg-white"><strong> {{$t('Submission date')}} </strong></td>
                <td class="bg-white"> {{DDMMMYY(data['submission_date'])}}</td>
            </tr>
        </table> 
    </div>      
</template>

<script>
import { DDMMMYY } from '../../utils'
export default {
    name : 'InformationGeneral',
    computed : {
        data(){
            return this.$store.getters['detail/data']
        }
    },
    methods : {
        DDMMMYY
    },

}
</script>

<style>

</style>